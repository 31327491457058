import React, {
	Children,
	cloneElement,
	FC,
	ReactElement,
	ReactNode,
	useEffect,
	useState,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
	ICardProps,
} from './bootstrap/Card';
import Button from './bootstrap/Button';
import useDarkMode from '../hooks/useDarkMode';
import { TColor } from '../type/color-type';
import Spinner from './bootstrap/Spinner';

interface IWizardItemProps extends React.HTMLAttributes<HTMLDivElement> {
	id: string;
	children: ReactNode;
	title?: string;
	className?: string;
}
export const WizardItem: FC<IWizardItemProps> = ({
	id,
	title = undefined,
	children,
	className = undefined,
	...props
}) => {
	return (
		<section
			id={id}
			className={classNames('wizard-item', className)}
			role='tabpanel'
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}>
			{children}
		</section>
	);
};
WizardItem.propTypes = {
	id: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	title: PropTypes.string,
	className: PropTypes.string,
};

interface IWizardProps extends ICardProps {
	children: ReactElement<IWizardItemProps> | ReactElement<IWizardItemProps>[];
	color?: TColor;
	isHeader?: boolean | 'withButton';
	prevlabel?: string;
	nextlabel?: string | string[];
	submitlabel?: string;
	hideprevbutton?: string;
	isMobile?: boolean;
	enableselectstep?: string;
	icon0?: string;
	icon1?: string;
	icon2?: string;
	customAction0?: () => void;
	customAction1?: () => void;
	customAction2?: () => void;
}

const Wizard: FC<IWizardProps> = ({
	children,
	onSubmit,
	onNext,
	isNextDisabled,
	isSubmitDisabled,
	isSubmitting,
	isHeader = false,
	color = 'primary',
	stretch = undefined,
	prevlabel = 'Previous',
	nextlabel = 'Next',
	submitlabel = 'Submit',
	hideprevbutton = 'false',
	isMobile = undefined,
	enableselectstep = 'true',
	icon0 = undefined,
	icon1 = undefined,
	icon2 = undefined,
	customAction0 = undefined,
	customAction1 = undefined,
	customAction2 = undefined,
	...props
}) => {
	const { themeStatus } = useDarkMode();
	const [activeItemIndex, setActiveItemIndex] = useState(0);

	const childCount = Array.isArray(children) ? children?.length : 1;

	const getTitleName = (i: number) => {
		return `Step ${i + 1}`;
	};

	const prevBtn = hideprevbutton === 'false' && !!activeItemIndex && (
		<Button color={color} isLink onClick={() => setActiveItemIndex(activeItemIndex - 1)}>
			{prevlabel || 'Previous'}
		</Button>
	);

	const icons = [icon0, icon1, icon2];

	const nextLabelArray = Array.isArray(nextlabel) ? nextlabel : [nextlabel || 'Next'];

	const nextBtn = (
		<>
			<Button
				className={classNames({ 'd-none': childCount === activeItemIndex + 1 }, 'me-0')}
				aria-hidden={childCount === activeItemIndex + 1}
				color={color}
				isDisable={isNextDisabled}
				isLight
				onClick={() => {
					if (activeItemIndex === 0 && customAction0) {
						customAction0();
					}
					if (activeItemIndex === 1 && customAction1) {
						customAction1();
					}
					if (onNext) {
						onNext();
					}
					setActiveItemIndex(activeItemIndex + 1);
				}}>
				{nextLabelArray[activeItemIndex] || nextLabelArray[0]}
			</Button>
			<Button
				className={classNames({ 'd-none': childCount !== activeItemIndex + 1 })}
				aria-hidden={childCount !== activeItemIndex + 1}
				onClick={() => {
					if (onSubmit) {
						if (customAction2) {
							customAction2();
						}
						onSubmit();
					}
				}}
				isDisable={isSubmitDisabled || isSubmitting}
				color={color}>
				{isSubmitting ? (
					<Spinner inButton={'onlyIcon'} isSmall={true} color={'light'} />
				) : (
					<>{submitlabel || 'Submit'}</>
				)}
			</Button>
		</>
	);

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<Card
			stretch={stretch}
			tag='form'
			onSubmit={onSubmit}
			{...props}
			style={isMobile ? { overflowY: 'scroll' } : {}}>
			{!!isHeader && (
				<CardHeader>
					<CardLabel
						icon={(icon0 || icon1 || icon2) ? icons[activeItemIndex] : 'Assignment'}
						iconColor={color}>
						{Children.map(children, (child, index) => (
							<CardTitle
								key={child?.props?.id}
								className={index !== activeItemIndex ? 'd-none' : undefined}>
								{child?.props.title || getTitleName(index)}
							</CardTitle>
						))}
					</CardLabel>
					{isHeader === 'withButton' && (
						<CardActions>
							{prevBtn}
							{nextBtn}
						</CardActions>
					)}
				</CardHeader>
			)}
			<CardBody isScrollable={isMobile ? !!stretch : false}>
				<div className='wizard-progress position-relative'>
					<div className='progress'>
						<div
							className={classNames('progress-bar', {
								[`bg-${color}`]: color !== 'primary',
							})}
							role='progressbar'
							style={{ width: `${(100 / (childCount - 1)) * activeItemIndex}%` }}
							aria-valuenow={(100 / (childCount - 1)) * activeItemIndex}
							aria-valuemin={0}
							aria-valuemax={100}
							aria-label='progress'
						/>
					</div>
					{Children.map(children, (_child, index) => (
						<button
							type='button'
							className={classNames(
								'wizard-progress-btn',
								'position-absolute p-0 top-0',
								'translate-middle',
								'btn btn-sm',
								{
									[`btn-${color}`]: activeItemIndex >= index,
									[`btn-${themeStatus}`]: activeItemIndex < index,
								},
								'rounded-pill',
							)}
							style={{
								left: `${(100 / (childCount - 1)) * index}%`,
							}}
							onClick={() => {
								if (enableselectstep === 'true') {
									setActiveItemIndex(index);
								}
							}}>
							{index + 1}
						</button>
					))}
				</div>

				<div className='wizard'>
					{Children.map(children, (child, index) =>
						cloneElement(child as unknown as ReactElement, {
							className: index !== activeItemIndex ? 'd-none' : '',
							'aria-hidden': index !== activeItemIndex,
						}),
					)}
				</div>
			</CardBody>
			<CardFooter>
				<CardFooterLeft>{prevBtn}</CardFooterLeft>
				<CardFooterRight>{nextBtn}</CardFooterRight>
			</CardFooter>
		</Card>
	);
};
Wizard.propTypes = {
	// @ts-ignore
	children: PropTypes.node.isRequired,
	color: PropTypes.oneOf([
		null,
		'primary',
		'secondary',
		'success',
		'info',
		'warning',
		'danger',
		'light',
		'dark',
	]),
	// @ts-ignore
	isHeader: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['withButton'])]),
	onSubmit: PropTypes.func.isRequired,
	// @ts-ignore
	stretch: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['full', 'semi'])]),
};

export default Wizard;
