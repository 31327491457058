// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-swipeable-list/dist/styles.css';
import { findAll, findDetail, findPlayers } from '../../../api/player/player.api';
import {
	ExtendedPlayerDTO,
	FindPlayersFilterBy,
	FindPlayersSortBy,
	PlayerDetail,
} from '../../../api/player/player.model';
import placeholderSelectPlayer from '../../../assets/img/placeholder-select-player.svg';
import Ads from '../../../components/Ads';
import AddPlayerToTeam from '../../../components/addPlayerToTeam';
import Button from '../../../components/bootstrap/Button';
import Card, { CardBody, CardHeader } from '../../../components/bootstrap/Card';
import Input from '../../../components/bootstrap/forms/Input';
import InputGroup from '../../../components/bootstrap/forms/InputGroup';
import { AuctionContext } from '../../../contexts/auctionContext';
import AuthContext from '../../../contexts/authContext';
import { InfluencerContext } from '../../../contexts/influencerContext';
import { ToastContext } from '../../../contexts/toastContext';
import { getOpen, setOpen } from '../../../helpers/navigation';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import WebSocketClient, {
	MessageTypes,
	WebSocketResponse,
} from '../../../websockets/websockets.config';
import PlayerDetailsDesktop from '../playersList/components/playerDetailsDesktop';
import PlayerDetailsMobile from '../playersList/components/playerDetailsMobile';
import { RiepilogoColumn, isRiepilogoActive } from './components/RiepilogoColumn';
import { SubHeaderAuctionActive } from './components/SubHeaderAuctionActive';
import { AssignPlayerModal } from './components/assignPlayerModal';
import { OrderAndFilterOffCanvas } from './components/orderAndFilterOffCanvas';
import { PlayersListAuction } from './components/playersListAuction';
import { SearchForCall } from './components/searchForCall';
import { TeamView } from './components/teamView';
import Badge from '../../../components/bootstrap/Badge';

let timeout: NodeJS.Timeout;

const AuctionActive = () => {
	const { userSubscriptionInfo } = useContext(AuthContext);
	const { action } = useParams();

	const { authToken, tokenReceived } = useContext(AuthContext);
	const {
		auctionId,
		setAuctionId,
		auctionStatus,
		setAuctionStatus,
		setTeams,
		setAlreadySelectedTeam,
		isAuctionActive,
		setIsAuctionActive,
		auctionHistory,
		setAuctionHistory,
	} = useContext(AuctionContext)!;
	const { setToast } = useContext(ToastContext);

	const [players, setPlayers] = useState<ExtendedPlayerDTO[]>([]);

	const [selectedSortBy, setSelectedSortBy] = useState<FindPlayersSortBy[]>([
		'role,asc',
		'surname,asc',
	]);
	const [selectedFilterBy, setSelectedFilterBy] = useState<FindPlayersFilterBy>({});

	const [selectedPlayerId, setSelectedPlayerId] = useState<string>();
	const [selectedPlayerName, setSelectedPlayerName] = useState<string>('');
	const [playerName, setPlayerName] = useState<string>('');

	const [isMobile, setIsMobile] = useState(false);
	const [teamName, setTeamName] = useState<string>('');
	const [playerPrice, setPlayerPrice] = useState<string>('');

	useEffect(() => {
		setTeams([]);
	}, []);
	useEffect(() => {
		if (playerName)
			setToast({
				level: 'success',
				title: `Giocatore assegnato`,
				message: `La squadra ${teamName} si è aggiudicata ${playerName} per ${playerPrice}`,
			});
	}, [playerName]);

	const { selectedInfluencer } = useContext(InfluencerContext) || {};
	const [showAddPlayerToTeam, setShowAddPlayerToTeam] = useState<boolean>(false);
	const [rowView, setRowView] = useState<boolean>(true);
	const [squadreActive, setSquadreActive] = useState<boolean>(false);
	const [giocatoriActive, setGiocatoriActive] = useState<boolean>(true);
	const [calledPlayerAuto, setCalledPlayerAuto] = useState<PlayerDetail | undefined>(undefined);
	const [calledPlayer, setCalledPlayer] = useState<AuctionFirstCallResponse | undefined>(
		undefined,
	);
	const [playerDetail, setPlayerDetail] = useState<any>(undefined);
	const [betFantamillions, setBetFantamillions] = useState<number>(0);
	const [newFantamillions, setNewFantamillions] = useState<number>(0);
	const [customFantamillions, setCustomFantamillions] = useState<number>(0);

	const [called, setCalled] = useState<boolean | undefined>(undefined);
	const [assigned, setAssigned] = useState<boolean | undefined>(undefined);

	const navigate = useNavigate();

	const [userThatCalled, setUserThatCalled] = useState<string | undefined>(undefined);
	const [secondsRemaining, setSecondsRemaining] = useState<number>(0);
	const [countdown, setCountdown] = useState<number>(0);
	const [callId, setCallId] = useState<string | undefined>(undefined);
	const [lastUserRaised, setLastUserRaised] = useState<string | undefined>(undefined);
	const [startingPrice, setStartingPrice] = useState<number>(0);

	interface AuctionFirstCallResponse {
		playerId: string;
		playerName: string;
		playerTeam: string;
		playerRole: string;
		playerMantraRole: Set<string>;
	}

	useEffect(() => {
		if (tokenReceived) {
			const open = getOpen(action, 'dettagli');
			if (open && open.length > 0) {
				setSelectedPlayerId(open[0]);
			}
		}
	}, [tokenReceived]);

	useEffect(() => {
		if (selectedPlayerId) {
			setOpen(navigate, '/attiva', action, 'dettagli', [selectedPlayerId], true);
			findDetail(selectedPlayerId).then((res) => {
				setPlayerDetail(res);
			});
		}
	}, [selectedPlayerId]);

	interface TeamAuction {
		id: string;
		name: string;
		goalkeepers: any[];
		midfielders: any[];
		defenders: any[];
		forwards: any[];
		initialCredits: number;
		remainingCredits: number;
		personal: boolean;
	}

	useEffect(() => {
		if (auctionId && authToken) {
			WebSocketClient.getInstance(authToken, auctionId).then((client) => {
				client.subscribe('private-auction', (data: WebSocketResponse) => {
					switch (data.messageType) {
						case MessageTypes.ERROR:
							setToast({
								level: 'error',
								title: data.content.errorMessage.message,
								message: data.content.errorMessage.description,
							});
							break;
						case MessageTypes.TEAM_UPDATE:
							setTeams((prevTeams: TeamAuction[]) => {
								const teamExists = prevTeams?.some(
									(team) => team.id === data.content.id,
								);
								if (!teamExists) {
									return [...prevTeams, data.content] as TeamAuction[];
								}
								return prevTeams;
							});
							break;
						case MessageTypes.TEAM_ALREADY_SELECTED:
							setAlreadySelectedTeam(data.content);
							break;
						case MessageTypes.AUCTION_DETAILS:
							setIsAuctionActive(data.content.active);
							break;
						case MessageTypes.AUCTION_HISTORY:
							setAuctionHistory(data.content.concat(auctionHistory));
							break;
						case MessageTypes.STATUS:
							setAuctionStatus(data.content);
							break;
						default:
							break;
					}
				});
				client.subscribe('public-auction', (data: WebSocketResponse) => {
					switch (data.messageType) {
						case MessageTypes.ERROR:
							setToast({
								level: 'error',
								title: data.content.errorMessage.message,
								message: data.content.errorMessage.description,
							});
							break;
						case MessageTypes.AUCTION_STARTED:
							setIsAuctionActive(data.content.active);
							break;
						case MessageTypes.AUCTION_HISTORY:
							setAuctionHistory(data.content.concat(auctionHistory));
							break;
						case MessageTypes.STATUS:
							setAuctionStatus(data.content);
							break;
						case MessageTypes.CALL_AUTO:
							findDetail(data.content.playerId).then((player) => {
								setCalledPlayerAuto(player);
								setCalled(false);
								setAssigned(false);
							});
							break;
						case MessageTypes.CALL_STARTED:
							setNewFantamillions(data.content.startingPrice);
							setStartingPrice(data.content.startingPrice);
							setUserThatCalled(auctionStatus?.users[data.content.userId].name);
							setCountdown(data.content.seconds);
							setSecondsRemaining(data.content.seconds);
							setCallId(data.content.id);

							const player: AuctionFirstCallResponse = {
								playerId: data.content.playerId,
								playerName: data.content.playerName,
								playerTeam: data.content.playerTeam,
								playerRole: data.content.playerRole,
								playerMantraRole: data.content.playerMantraRole,
							};

							setCalledPlayer(player);
							setCalled(true);
							setAssigned(false);
							break;

						case MessageTypes.NEW_USER_JOINED:
							client.publish(`app/auctions/${auctionId}/status`, '');
							break;

						case MessageTypes.RAISE_ACCEPTED:
							setToast({
								level: 'success',
								title: `Rilancio effettuato`,
								message: `L'utente ${
									auctionStatus?.users[data.content.userId].name
								} ha offerto ${data.content.counterOfferPrice}`,
							});
							setBetFantamillions(data.content.counterOfferPrice);
							setNewFantamillions(data.content.counterOfferPrice);
							setLastUserRaised(auctionStatus?.users[data.content.userId].name);
							setCountdown(data.content.seconds);
							break;
						case MessageTypes.CALL_CLOSED:
							setBetFantamillions(0);
							setNewFantamillions(0);
							setCalledPlayer(undefined);
							setCalled(false);
							break;

						case MessageTypes.TEAM_UPDATE:
							setTeams((prevTeams: TeamAuction[]) => {
								const teamExists = prevTeams?.some(
									(team) => team.id === data.content.id,
								);

								if (teamExists && prevTeams.length > 0) {
									return prevTeams?.map((team) => {
										if (team.id === data.content.id) {
											const updatedGoalkeepers = [
												...(team?.goalkeepers ?? []).filter(
													(existingGk: any) =>
														(data.content?.goalkeepers ?? []).some(
															(newGk: any) =>
																newGk.id === existingGk.id,
														),
												),
												...(data.content?.goalkeepers ?? []).filter(
													(newGk: any) =>
														!(team?.goalkeepers ?? []).some(
															(existingGk: any) =>
																existingGk.id === newGk.id,
														),
												),
											];

											const newGoalkeepers = data.content.goalkeepers?.filter(
												(newDf: any) => {
													return !team.goalkeepers?.some(
														(existingDf: any) =>
															existingDf.id === newDf.id,
													);
												},
											);

											if (newGoalkeepers && newGoalkeepers.length > 0) {
												setPlayerName(newGoalkeepers[0].name);
												setPlayerPrice(newGoalkeepers[0].price);
											}

											const updatedMidfielders = [
												...(team?.midfielders ?? []).filter(
													(existingMf: any) =>
														data.content.midfielders?.some(
															(newMf: any) =>
																newMf.id === existingMf.id,
														),
												),
												...(data.content?.midfielders ?? []).filter(
													(newMf: any) =>
														!team.midfielders?.some(
															(existingMf: any) =>
																existingMf.id === newMf.id,
														),
												),
											];

											const newMidfielders = data.content.midfielders?.filter(
												(newDf: any) => {
													return !team.midfielders?.some(
														(existingDf: any) =>
															existingDf.id === newDf.id,
													);
												},
											);

											if (newMidfielders && newMidfielders.length > 0) {
												setPlayerName(newMidfielders[0].name);
												setPlayerPrice(newMidfielders[0].price);
											}

											const updatedForwards = [
												...(team?.forwards ?? []).filter(
													(existingFw: any) =>
														data.content.forwards?.some(
															(newFw: any) =>
																newFw.id === existingFw.id,
														),
												),
												...(data.content?.forwards ?? []).filter(
													(newFw: any) =>
														!team.forwards?.some(
															(existingFw: any) =>
																existingFw.id === newFw.id,
														),
												),
											];

											const newForwards = data.content.forwards?.filter(
												(newDf: any) => {
													return !team.forwards?.some(
														(existingDf: any) =>
															existingDf.id === newDf.id,
													);
												},
											);

											if (newForwards && newForwards.length > 0) {
												setPlayerName(newForwards[0].name);
												setPlayerPrice(newForwards[0].price);
											}

											const updatedDefenders = [
												...(team?.defenders ?? []).filter(
													(existingDf: any) =>
														data.content.defenders?.some(
															(newDf: any) =>
																newDf.id === existingDf.id,
														),
												),
												...(data.content.defenders ?? []).filter(
													(newDf: any) =>
														!team.defenders?.some(
															(existingDf: any) =>
																existingDf.id === newDf.id,
														),
												),
											];

											const newDefenders = data.content.defenders?.filter(
												(newDf: any) => {
													return !team.defenders?.some(
														(existingDf: any) =>
															existingDf.id === newDf.id,
													);
												},
											);

											if (newDefenders && newDefenders.length > 0) {
												setPlayerName(newDefenders[0].name);
												setPlayerPrice(newDefenders[0].price);
											}

											// Deduplicate players
											const uniqueGoalkeepers = updatedGoalkeepers?.filter(
												(gk, index, self) =>
													index ===
													self?.findIndex((t) => t.id === gk.id),
											);

											const uniqueMidfielders = updatedMidfielders?.filter(
												(mf, index, self) =>
													index ===
													self?.findIndex((t) => t.id === mf.id),
											);

											const uniqueForwards = updatedForwards?.filter(
												(fw, index, self) =>
													index ===
													self?.findIndex((t) => t.id === fw.id),
											);

											const uniqueDefenders = updatedDefenders?.filter(
												(df, index, self) =>
													index ===
													self?.findIndex((t) => t.id === df.id),
											);

											return {
												...team,
												remainingCredits: data.content.remainingCredits,
												goalkeepers: uniqueGoalkeepers,
												midfielders: uniqueMidfielders,
												forwards: uniqueForwards,
												defenders: uniqueDefenders,
											};
										}
										return team;
									}) as TeamAuction[];
								}

								return prevTeams as TeamAuction[];
							});
							setTeamName(data.content.name);

							break;
						case MessageTypes.AUCTION_ENDED:
							setToast({
								level: 'success',
								title: `Asta terminata`,
								message: `L'asta è terminata`,
							});
							navigate('/asta');
							setAuctionId('');
							setAuctionHistory([]);
							localStorage.setItem('auctionId', '');
							client.disconnect();

							break;

						default:
							break;
					}
				});
			});
		}
	}, [auctionId, authToken]);

	const auctionRaise = (counterOfferPrice: number) => {
		setNewFantamillions(newFantamillions + counterOfferPrice);
		//setBetFantamillions(newFantamillions + counterOfferPrice);
		if (authToken && auctionId && callId) {
			WebSocketClient.getInstance(authToken, auctionId).then((client) => {
				client.publish(`/app/auctions/${auctionId}/calls/${callId}`, {
					countOfferPrice: newFantamillions + counterOfferPrice,
				});
			});
			setCustomFantamillions(0);
		}
	};

	useEffect(() => {}, []);

	const assignPlayer = (team: any, price: number) => {
		if (authToken && auctionId && selectedPlayerId)
			WebSocketClient.getInstance(authToken, auctionId).then((client) => {
				client.publish(
					`/app/auctions/${auctionId}/players/${selectedPlayerId}/directives`,
					{
						teamId: team.id,
						price: price,
					},
				);
			});
	};

	/**
	 * Gets called when a player is selected to be called
	 * If the player is set as undefined, the player is unassigned
	 * If the player is set, a call is done to the websocket topic and the player is assigned
	 *   once the "CALL_STARTED" message is received on the public-auction topic
	 *
	 * @param player ExtendedPlayerDTO | undefined
	 */
	const selectCalledPlayer = (player: ExtendedPlayerDTO | undefined) => {
		if (authToken && auctionId && player) {
			WebSocketClient.getInstance(authToken, auctionId).then((client) => {
				client.publish(`/app/auctions/${auctionId}/players/${player.id}`, '');
			});
		}
		if (!player) {
			setCalledPlayer(undefined);
			setAssigned(true);
			setCalled(false);
		}
	};

	const selectDiscardPlayer = (player: ExtendedPlayerDTO | undefined) => {
		if (authToken && auctionId && player) {
			WebSocketClient.getInstance(authToken, auctionId).then((client) => {
				client.publish(`/app/auctions/${auctionId}/players/${player.id}/removal`, '');
			});
		}
		if (!player) {
			setCalledPlayer(undefined);
			setAssigned(true);
			setCalled(false);
		}
	};

	useEffect(() => {
		function handleResize() {
			setIsMobile(window.innerWidth < 769);
		}

		handleResize(); // Check initially
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		if (secondsRemaining > 0) {
			setCountdown(secondsRemaining);

			const interval = setInterval(() => {
				setCountdown((currentCountdown) => {
					if (currentCountdown === 0) {
						setSecondsRemaining(0);
						clearInterval(interval);
					}
					return currentCountdown - 1;
				});
			}, 1000);

			return () => clearInterval(interval);
		}
	}, [secondsRemaining]);

	const SIZE = 50;
	const onSearchPlayers = (property: FindPlayersSortBy[], filterBy: FindPlayersFilterBy) => {
		setSelectedSortBy(property);
		const filters = { page: 0, size: SIZE, sort: property, ...filterBy };
		if (selectedInfluencer) {
			filters.influencerId = selectedInfluencer.id;
		}
		return findAll(filters).then((res) => {
			if (filters.page > 0) {
				setPlayers(players.concat(res.content));
			} else {
				setPlayers(res.content);
			}
		});
	};

	useEffect(() => {
		if (selectedInfluencer) {
			onSearchPlayers(selectedSortBy, selectedFilterBy);
		}
	}, [selectedInfluencer]);

	const renderSearchInput = () => {
		return (
			<Input
				id='searchInput'
				type='search'
				className='form-control border-0 shadow-none bg-transparent'
				placeholder='Cerca Giocatore'
				value={selectedFilterBy.search}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
					const newSelectedFilterBy = {
						...selectedFilterBy,
						search: e.target.value,
					};
					setSelectedFilterBy(newSelectedFilterBy);
					if (timeout) {
						clearTimeout(timeout);
					}
					timeout = setTimeout(() => {
						onSearchPlayers(selectedSortBy, newSelectedFilterBy);
					}, 500);
				}}
			/>
		);
	};

	const renderPlayerPreferenceButtons = (playerId: string) => {
		return (
			<>
				<Button
					className='flex-grow-1'
					isOutline={!called}
					color={'success'}
					isDisable={calledPlayer !== undefined || !isAuctionActive}
					onClick={() => {
						selectCalledPlayer(playerDetail);
					}}>
					Chiama
				</Button>
				<Button
					className='flex-grow-1'
					isOutline={/**!props.assigned **/ true}
					color={'danger'}
					isDisable={calledPlayer !== undefined || !isAuctionActive}
					onClick={() => {
						setOpen(navigate, '/attiva', action, 'assegna', [], true);
						selectCalledPlayer(undefined);
					}}>
					Assegna
				</Button>
			</>
		);
	};

	const getBadgeClass = (role: string) => {
		if (!role) {
			return '';
		}
		switch (role) {
			case 'P':
			case 'Por':
				return 'bg-warning';
			case 'D':
			case 'Ds':
			case 'Dc':
			case 'Dd':
				return 'bg-success';
			case 'C':
			case 'E':
			case 'M':
				return 'bg-info';
			case 'W':
			case 'T':
				return 'bg-mantraWT';
			default:
				return 'bg-danger';
		}
	};

	const renderCallAutoSection = () => {
		const selectedPlayer = players.find((player) => player.id === calledPlayerAuto?.id);
		return (
			<Card className={'mt-2 mb-2'} borderColor={'success'} borderSize={3}>
				<CardBody>
					<div className='row'>
						<div className='col-12 col-md-6'>
							<div className='d-flex justify-content-center align-items-center'>
								<div className='d-flex'>
									<h4 className={'m-0'}>
										<Badge
											rounded={1}
											shadow={'sm'}
											className={`mx-1 ${getBadgeClass(
												calledPlayerAuto?.role ?? '',
											)}`}>
											{calledPlayerAuto?.role}
										</Badge>
										{calledPlayerAuto?.mantraRole?.map((mantraRole, index) => (
											<Badge
												rounded={1}
												shadow={'lg'}
												className={getBadgeClass(mantraRole)}>
												{mantraRole}
											</Badge>
										))}
									</h4>
								</div>
								<div className='fw-bold fs-3 mx-1'>{selectedPlayer?.surname}</div>
								<div className='fw-normal fs-6 mx-1'>{selectedPlayer?.team}</div>
							</div>
						</div>
						<div className='col-12 col-md-6'>
							<div className='d-flex justify-content-center align-items-center gap-3'>
								<Button
									className='w-25 bg-l75-success text-white'
									onClick={() => {
										selectCalledPlayer(selectedPlayer);
										setCalledPlayerAuto(undefined);
									}}>
									Chiama
								</Button>
								<SubheaderSeparator />
								<Button
									className='w-25 bg-l75-danger text-white'
									onClick={() => {
										selectDiscardPlayer(selectedPlayer);
									}}>
									Scarta
								</Button>
							</div>
						</div>
					</div>
				</CardBody>
			</Card>
		);
	};

	const renderCallSection = () => {
		return (
			<Card className={'mt-2 mb-2'} borderColor={'success'} borderSize={3}>
				<CardBody>
					<div className='row'>
						<div className='col-6 col-md-4'>
							<div className='d-flex justify-content-center justify-content-lg-start align-items-center'>
								<div className='d-inline-flex flex-column justify-content-center align-items-center align-items-stretch me-2'>
									<h4 className='mb-1'>
										<div
											className={`badge rounded-end rounded-0 ${getBadgeClass(
												calledPlayer?.playerRole ?? '',
											)}`}>
											{calledPlayer?.playerRole}
										</div>
									</h4>
									{Array.from(calledPlayer?.playerMantraRole || [])?.map(
										(mantraRole: string, index: number) => (
											<h6 key={index} className='mb-0'>
												<div
													className={`badge rounded-end rounded-0 ${getBadgeClass(
														mantraRole,
													)}`}>
													{mantraRole}
												</div>
											</h6>
										),
									)}
								</div>
								<div className='d-flex flex-column'>
									<p className='mb-0 fw-bold lh-1'>{calledPlayer?.playerTeam}</p>
									<p className='fw-bold fs-4 mb-0 lh-1'>
										<b className='mb-0 fw-bolder lh-1'>
											{calledPlayer?.playerName}
										</b>
									</p>
									<p className='text-muted mb-0 mt-2'>
										Chiamato da: {userThatCalled}
									</p>
								</div>
							</div>
						</div>
						<div className='col-6 col-md-2 col-lg-4'>
							<div className='text-center'>
								<div className='text-muted'>L'asta terminerà tra:</div>
								<div className='fw-bolder fs-3'>
									{countdown >= 0 ? countdown : 'In finalizzazione…'}
								</div>
								<div className='text-muted'>secondi</div>
							</div>
						</div>
						<div className='col-12 col-md-6 col-lg-4 mt-4 mt-md-0'>
							<div className='d-flex justify-content-center justify-content-lg-end align-items-center gap-3'>
								<div className='text-center'>
									<div className='text-muted'>
										{lastUserRaised || userThatCalled}
									</div>
									<div className='fw-bolder fs-3'>
										{betFantamillions || startingPrice}
									</div>
									<div className='text-muted'>Crediti</div>
								</div>
								<SubheaderSeparator />
								<div className='d-flex flex-column gap-2'>
									<div className='d-flex justify-content-end align-items-center gap-1'>
										{[1, 2, 5, 10].map((value) => (
											<Button
												key={value}
												className='w-25 bg-l75-dark text-white'
												onClick={() => auctionRaise(value)}>
												+{value}
											</Button>
										))}
									</div>
									<InputGroup style={{ width: 200 }} className='rounded-1'>
										<Input
											placeholder='1'
											type='number'
											value={customFantamillions}
											onChange={(e: ChangeEvent<HTMLInputElement>) =>
												setCustomFantamillions(Number(e.target.value))
											}
											ariaLabel='Input'
										/>
										<Button
											color='dark'
											isOutline={true}
											onClick={() => auctionRaise(customFantamillions)}>
											Rilancia
										</Button>
									</InputGroup>
								</div>
							</div>
						</div>
					</div>
				</CardBody>
			</Card>
		);
	};

	const hasReloadedFlag = localStorage.getItem('hasReloaded');

	if (localStorage.getItem('auctionId') !== '' && hasReloadedFlag !== 'true') {
		localStorage.setItem('hasReloaded', 'true');
		window.location.reload();
	}

	if (localStorage.getItem('auctionId') === '') {
		return <></>;
	}

	return (
		<PageWrapper
			container='fluid'
			title='Lista Calciatori'
			isProtected={true}
			showAds={false}
			className={`${isMobile ? 'mt-5 pt-2' : ''}`}>
			<SubHeaderAuctionActive
				parentUri='/attiva'
				action={action}
				isMobile={isMobile}
				giocatoriActive={giocatoriActive}
				squadreActive={squadreActive}
				rowView={rowView}
				setGiocatoriActive={setGiocatoriActive}
				setSquadreActive={setSquadreActive}
				setRowView={setRowView}
			/>

			<Page container='fluid' className={'pt-0'}>
				<div className='row'>
					<div className='col-12'>
						<Ads />
						{calledPlayerAuto && renderCallAutoSection()}
						{calledPlayer && renderCallSection()}
						{!calledPlayer && !calledPlayerAuto && (
							<SearchForCall
								isMobile={isMobile}
								parentUri={'/attiva'}
								action={action}
								players={players}
								assigned={assigned}
								setCalled={setCalled}
								selectCalledPlayer={selectCalledPlayer}
								setSelectedPlayerId={setSelectedPlayerId}
							/>
						)}
					</div>
				</div>

				<div className='row h-100'>
					{!isMobile ? (
						<>
							{giocatoriActive && (
								<>
									<div
										className={`col-md-${
											isRiepilogoActive(action) ? '4' : '6'
										}`}>
										<Card className='card-stretch-full overflow-hidden'>
											<CardHeader className='d-flex flex-row gap-2 align-items-center'>
												<div className='shadow bg-body rounded d-flex flex-row justify-content-center align-items-center flex-grow-1 my-auto'>
													<label
														className='border-0 bg-transparent cursor-pointer me-0'
														htmlFor='searchInput'>
														<svg
															viewBox='0 0 24 24'
															fill='currentColor'
															className='svg-icon--material svg-icon svg-icon-2x text-primary ps-2'
															data-name='Material--Search'>
															<path d='M0 0h24v24H0V0z' fill='none' />
															<path d='M15.5 14h-.79l-.28-.27A6.471 6.471 0 0016 9.5 6.5 6.5 0 109.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'></path>
														</svg>
													</label>
													{renderSearchInput()}
												</div>
												<OrderAndFilterOffCanvas
													isMobile={isMobile}
													parentUri={'/attiva'}
													action={action}
													selectedFilterBy={selectedFilterBy}
													setSelectedFilterBy={setSelectedFilterBy}
													selectedSortBy={selectedSortBy}
													onSearchPlayers={onSearchPlayers}
													setSelectedSortBy={setSelectedSortBy}
												/>
											</CardHeader>
											<PlayersListAuction
												isMobile={isMobile}
												setSelectedPlayerId={setSelectedPlayerId}
												setSelectedPlayerName={setSelectedPlayerName}
												setShowAddPlayerToTeam={setShowAddPlayerToTeam}
												players={players}
												selectedFilterBy={selectedFilterBy}
												selectedSortBy={selectedSortBy}
												onSearchPlayers={onSearchPlayers}
											/>
										</Card>
									</div>
									<div
										className={`col-md-${
											isRiepilogoActive(action) ? '4' : '6'
										}`}>
										<Card className='card-stretch-full overflow-hidden'>
											{selectedPlayerId && (
												<CardHeader className='d-flex flex-row justify-center items-center gap-2'>
													{renderPlayerPreferenceButtons(
														selectedPlayerId,
													)}
												</CardHeader>
											)}
											<CardBody
												isScrollable={true}
												className={`${
													!selectedPlayerId
														? 'd-flex justify-content-center align-items-center'
														: ''
												} pt-0`}>
												{!selectedPlayerId && (
													<div
														className={`d-flex flex-column justify-content-center align-items-center`}>
														<img
															src={placeholderSelectPlayer}
															alt='Placeholder'
															style={{ width: '50%' }}
														/>
														<div>
															Seleziona un giocatore per vedere le sue
															informazioni
														</div>
													</div>
												)}
												{selectedPlayerId && (
													<div>
														<PlayerDetailsDesktop
															playerId={selectedPlayerId}
															selectedInfluencer={selectedInfluencer}
															userSubscriptionInfo={
																userSubscriptionInfo
															}
															playerDetail={playerDetail}
														/>
													</div>
												)}
											</CardBody>
										</Card>
									</div>
								</>
							)}
							{!giocatoriActive && (
								<div className={`col-md-${isRiepilogoActive(action) ? '8' : '12'}`}>
									<TeamView
										isMobile={isMobile}
										parentUri='/attiva'
										action={action}
										rowView={rowView}
									/>
								</div>
							)}

							<AssignPlayerModal
								assignPlayer={assignPlayer}
								assigned={assigned}
								calledPlayer={calledPlayer}
								selectCalledPlayer={selectCalledPlayer}
								action={action}
								parentUri='/attiva'
							/>

							<RiepilogoColumn
								parentUri='/attiva'
								action={action}
								isMobile={isMobile}
							/>
						</>
					) : (
						<>
							{giocatoriActive && (
								<Card className='card-stretch-full px-0'>
									<div className='col-12 mb-3 ml-3'>
										<div className='d-flex gap-2 justify-content-center'>
											<CardHeader className='py-0'>
												<div className='shadow bg-body rounded d-flex flex-row align-items-center my-auto'>
													<label
														className='border-0 bg-transparent cursor-pointer me-0 ml-2'
														htmlFor='searchInput'>
														<svg
															viewBox='0 0 24 24'
															fill='currentColor'
															className='svg-icon--material svg-icon svg-icon-2x text-primary'
															data-name='Material--Search'>
															<path d='M0 0h24v24H0V0z' fill='none' />
															<path d='M15.5 14h-.79l-.28-.27A6.471 6.471 0 0016 9.5 6.5 6.5 0 109.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'></path>
														</svg>
													</label>
													{renderSearchInput()}
												</div>
												<OrderAndFilterOffCanvas
													isMobile={isMobile}
													parentUri={'/attiva'}
													action={action}
													selectedFilterBy={selectedFilterBy}
													setSelectedFilterBy={setSelectedFilterBy}
													selectedSortBy={selectedSortBy}
													onSearchPlayers={onSearchPlayers}
													setSelectedSortBy={setSelectedSortBy}
												/>
											</CardHeader>
										</div>
									</div>
									<PlayersListAuction
										selectedFilterBy={selectedFilterBy}
										selectedSortBy={selectedSortBy}
										isMobile={isMobile}
										setSelectedPlayerId={setSelectedPlayerId}
										setSelectedPlayerName={setSelectedPlayerName}
										setShowAddPlayerToTeam={setShowAddPlayerToTeam}
										players={players}
										onSearchPlayers={onSearchPlayers}
									/>
								</Card>
							)}
							{!giocatoriActive && (
								<TeamView
									isMobile={isMobile}
									parentUri='/attiva'
									action={action}
									rowView={rowView}
								/>
							)}

							{giocatoriActive && (
								<PlayerDetailsMobile
									refreshList={() => {
										onSearchPlayers(selectedSortBy, selectedFilterBy);
										//refreshTeamDetail();
									}}
									playerId={selectedPlayerId}
									setPlayerId={setSelectedPlayerId}
									renderPlayerPreferenceButtons={renderPlayerPreferenceButtons}
									userSubscriptionInfo={userSubscriptionInfo}
									selectedInfluencer={selectedInfluencer}
									playerDetail={playerDetail}
									parentUri='/attiva'
									calledPlayer={!!calledPlayer}
								/>
							)}

							<AssignPlayerModal
								assignPlayer={assignPlayer}
								assigned={assigned}
								calledPlayer={calledPlayer}
								selectCalledPlayer={selectCalledPlayer}
								action={action}
								parentUri='/attiva'
							/>

							<RiepilogoColumn
								parentUri='/attiva'
								action={action}
								isMobile={isMobile}
							/>
						</>
					)}
				</div>
			</Page>
			{/*@ts-ignore*/}

			<AddPlayerToTeam
				refreshList={() => {
					onSearchPlayers(selectedSortBy, selectedFilterBy);
					//refreshTeamDetail();
				}}
				isVisible={showAddPlayerToTeam}
				playerId={selectedPlayerId}
				playerName={selectedPlayerName}
				action={action}
				setIsVisible={setShowAddPlayerToTeam}
				parentUri='/attiva'
			/>
		</PageWrapper>
	);
};

export default AuctionActive;
