import { useContext, useEffect, useState } from 'react';
import { ExtendedPlayerDTO, PlayerDetail } from '../../../../api/player/player.model';
import { findAll } from '../../../../api/team/team.api';
import Badge from '../../../../components/bootstrap/Badge';
import Button from '../../../../components/bootstrap/Button';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import { ListGroupItem } from '../../../../components/bootstrap/ListGroup';
import Icon from '../../../../components/icon/Icon';
import AuthContext from '../../../../contexts/authContext';
import { LeagueContext } from '../../../../contexts/leagueContext';
import { RenderTeamGridView } from './teamsGridView';
import { RenderTeamRowView } from './teamsRowView';
import { AuctionContext } from '../../../../contexts/auctionContext';
import Accordion from '../../../../components/bootstrap/Accordion';
import WebSocketClient, { WebSocketResponse } from '../../../../websockets/websockets.config';

interface TeamViewProps {
	isMobile: boolean;
	parentUri?: string;
	action?: string | undefined;
	rowView: boolean;
}

export const TeamView = (props: TeamViewProps) => {
	const { teams, setTeams } = useContext(AuctionContext)!;
	const { authToken } = useContext(AuthContext);
	const { auctionId } = useContext(AuctionContext)!;

	useEffect(() => {
		if (teams.length > 0) {
			const filteredTeams = teams.filter(
				(team: any) => team.id !== '' || !team.initialCredits,
			);
			setTeams(filteredTeams);
		}
	}, [teams]);

	function handleRemovePlayer(player: any, teamId: any) {
		if (auctionId && authToken) {
			WebSocketClient.getInstance(authToken, auctionId).then((client) => {
				client.publish(
					`/app/auctions/${auctionId}/teams/${teamId}/players/${player.id}`,
					'',
				);
			});
		}
	}

	const onePlayerListItem = (rowView: boolean, team: any, player: any) => {
		return (
			<ListGroupItem
				tag={'li'}
				className={`bg-l10-dark-hover d-flex justify-content-between align-items-center ${
					!rowView ? 'px-4' : ''
				}`}
				key={player.id}
				style={{ overflow: 'hidden', width: '100%' }}>
				<div
					className='d-flex align-items-center justify-content-start gap-2'
					style={{ flexGrow: 1, minWidth: 0 }}>
					<Icon icon={'People'} style={{ flexShrink: 0 }}></Icon>
					<h5
						className='my-auto text-truncate'
						style={{
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}>
						{player.name}
					</h5>
				</div>
				<div className='d-flex align-items-center justify-content-end gap-2 flex-shrink-0'>
					<Button
						size={'sm'}
						color={'danger'}
						isLink={true}
						icon={'Delete'}
						onClick={() => {
							handleRemovePlayer(player, team.id);
						}}
						className='flex-shrink-0'></Button>
					<Button
						size={'sm'}
						color={'dark'}
						isOutline={true}
						isDisable={true}
						shadow={'lg'}
						icon={'MonetizationOn'}
						className='text-nowrap flex-shrink-0'
						onClick={() => {
							handleRemovePlayer(player, team.id);
						}}>
						{player.price}
					</Button>
				</div>
			</ListGroupItem>
		);
	};

	return (
		<>
			{!props.isMobile && (
				<Card className='card-stretch-full overflow-hidden'>
					<CardBody
						isScrollable={true}
						style={{
							overflowX: !props.rowView ? 'scroll' : 'hidden',
						}}>
						<div
							className={`d-flex ${
								props.rowView ? 'flex-column' : 'flex-row'
							} gap-3`}>
							{teams && teams.length > 0 ? (
								teams.map((team: any, index: number) => {
									return props.rowView ? (
										<RenderTeamRowView
											key={index + JSON.stringify(team)}
											isMobile={props.isMobile}
											rowView={props.rowView}
											onePlayerListItem={onePlayerListItem}
											team={team}></RenderTeamRowView>
									) : (
										<RenderTeamGridView
											key={index + JSON.stringify(team)}
											team={team}
											rowView={props.rowView}
											onePlayerListItem={
												onePlayerListItem
											}></RenderTeamGridView>
									);
								})
							) : (
								<div className='text-center w-100'>Non sono presenti giocatori</div>
							)}
						</div>
					</CardBody>
				</Card>
			)}
			{props.isMobile && (
				<Card className='px-0'>
					<CardBody
						isScrollable={true}
						style={{
							overflowX: !props.rowView ? 'scroll' : 'hidden',
						}}>
						{props.rowView && (
							<div className='d-flex flex-column gap-3'>
								{teams?.map((team: any, index: number) => (
									<RenderTeamRowView
										isMobile={props.isMobile}
										rowView={props.rowView}
										onePlayerListItem={onePlayerListItem}
										team={team}></RenderTeamRowView>
								))}
							</div>
						)}
						{!props.rowView && (
							<div className='d-flex flex-row flex-row gap-3'>
								{teams?.map((team: any, index: number) => (
									<RenderTeamGridView
										team={team}
										rowView={props.rowView}
										onePlayerListItem={onePlayerListItem}></RenderTeamGridView>
								))}
							</div>
						)}
					</CardBody>
				</Card>
			)}
		</>
	);
};
