import React, { ChangeEvent, useEffect } from 'react';
import { useImportFromLeghe } from '../../../../../../../hooks/businessLogic/useImportFromLeghe';
import { ImportedLeague, ImportedTeam } from '../../../../../../../api/league/league.model';
import Select from '../../../../../../../components/bootstrap/forms/Select';
import Spinner from '../../../../../../../components/bootstrap/Spinner';
import Icon from '../../../../../../../components/icon/Icon';

interface ImportSecondStepProps {
	competition?: ImportedLeague;
	setCompetition: (myLeague: ImportedLeague) => void;
	team?: any;
	setTeam: (team: any) => void;
	leagueImportSuccess: boolean;
	importedLeague: any;
}

const ImportSecondStep = (props: ImportSecondStepProps) => {
	// const { isLoading, isError, importedLeagues, errorMessage } = useImportFromLeghe();

	useEffect(() => {
		if (props.leagueImportSuccess) {
			props.setCompetition(props.importedLeague[0]);
		}
	}, [props.leagueImportSuccess]);

	useEffect(() => {
		if (props.competition) {
			props.setTeam(props.competition.teams[0]);
		}
	}, [props.competition]);

	return (
		<>
			<div>
				{!props.leagueImportSuccess ? (
					<div
						className={
							'd-flex flex-row align-items-center justify-content-center mt-5'
						}>
						<Spinner isGrow={true} color={'dark'} />
					</div>
				) : props.importedLeague.length === 0 ? (
					<div
						className={
							'text-center text-danger h5 mb-2 mt-2 d-flex align-items-center justify-content-center flex-column'
						}>
						<Icon icon={'Warning'} size='2x' color={'danger'} className={'mb-3'} />
						{'Oops, qualcosa è andato storto!'}
					</div>
				) : (
					<>
						<label className={'mb-1'}>Seleziona competizione</label>
						<Select
							aria-label={'select'}
							ariaLabel={'select'}
							disabled={!props.leagueImportSuccess}
							list={props.importedLeague.map((league: any) => {
								return {
									text: league.name,
									value: league.id,
								};
							})}
							className={'mb-4'}
							defaultValue={props.competition ? props.competition.id : ''}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								const selectedCompetition = props.importedLeague.find(
									(competition: any) => competition.id === e.target.value,
								);
								if (selectedCompetition) {
									props.setCompetition(selectedCompetition);
								}
							}}
						/>
						<label className={'mb-1'}>Seleziona squadra</label>
						<Select
							aria-label={'select'}
							ariaLabel={'select'}
							disabled={!props.leagueImportSuccess}
							list={
								props.competition
									? props.competition.teams?.map((team) => {
											return {
												text: team.name,
												value: team.id,
											};
									  })
									: []
							}
							defaultValue={props.team ? props.team.id : ''}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								const selectedTeam = props.competition!.teams.find(
									(team) => team.id === e.target.value,
								);
								if (selectedTeam) {
									props.setTeam({
										id: '',
										name: selectedTeam.name,
										initialCredits: 0,
										importedTeamId: selectedTeam.id,
									});
								}
							}}
						/>
					</>
				)}
			</div>
		</>
	);
};

export default ImportSecondStep;
