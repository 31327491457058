import React, { useEffect, useState } from 'react';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import Navigation from '../../../layout/Navigation/Navigation';
import { pageLayoutTypesPagesMenu } from '../../../menu';
import useDeviceScreen from '../../../hooks/useDeviceScreen';
import Popovers from '../../../components/bootstrap/Popovers';
import { BrowserRouter, Route, Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Icon from '../../../components/icon/Icon';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../components/bootstrap/Modal';
import Button from '../../../components/bootstrap/Button';

const DefaultHeader = () => {
	const { width } = useDeviceScreen();

	const location = useLocation();
	//const [whatsappModalOpen, setWhatsappModalOpen] = useState<boolean>(false);

	const openInNewTab = ({ url }: { url: string }) => {
		window.open(url, '_blank', 'noopener,noreferrer');
	};

	if (location.pathname === '/pagamento-fallito' || location.pathname === '/pagamento-riuscito') {
		return <></>;
	}

	const isResult = (action: string | undefined) => Boolean(action?.includes('/whatsapp'));

	const navigate = useNavigate();

	const whatsappModalOpen = isResult(location.pathname);

	const setWhatsappModalOpen = (open: boolean) => {
		let newPathname = location.pathname;
		const whatsappIncluded = newPathname.includes('/whatsapp');

		if (open && !whatsappIncluded) {
			newPathname += 'whatsapp';
		} else if (!open && whatsappIncluded) {
			newPathname = newPathname.replace('/whatsapp', '');
		}

		navigate(newPathname);
	};

	return (
		<>
			<Header hasLeftMobileMenu={false}>
				<HeaderLeft>
					{/* <Navigation
					menu={{ ...pageLayoutTypesPagesMenu }}
					id='header-top-menu'
					horizontal={
						!!width && width >= Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
					}
				/> */}

					{location.pathname.includes('/setup-lega') && (
						// Add your content here that should be rendered only when location.pathname is "/setup-lega"
						<h2 className='fw-bolder text-dark'>Setup Lega</h2>
					)}

					{location.pathname.includes('/lista-calciatori') && (
						// Add your content here that should be rendered only when location.pathname is "/setup-lega"
						<h2 className='fw-bolder text-dark'>Lista Calciatori</h2>
					)}

					{location.pathname.includes('/asta') && (
						// Add your content here that should be rendered only when location.pathname is "/setup-lega"
						<h2 className='fw-bolder text-dark'>Aste</h2>
					)}

					{location.pathname.includes('/attiva') && (
						// Add your content here that should be rendered only when location.pathname is "/setup-lega"
						<h2 className='fw-bolder text-dark'>Asta In Corso</h2>
					)}

					{location.pathname.includes('/lista-creator') && (
						// Add your content here that should be rendered only when location.pathname is "/setup-lega"
						<h2 className='fw-bolder text-dark'>Creator</h2>
					)}

					{location.pathname === '/riepilogo' && (
						// Add your content here that should be rendered only when location.pathname is "/setup-lega"
						<h2 className='fw-bolder text-dark'>Riepilogo Squadre</h2>
					)}

					{location.pathname.includes('/campionato') && (
						// Add your content here that should be rendered only when location.pathname is "/setup-lega"
						<h2 className='fw-bolder text-dark'>Campionato</h2>
					)}

					{location.pathname.includes('/dubbione') && (
						// Add your content here that should be rendered only when location.pathname is "/setup-lega"
						<h2 className='fw-bolder text-dark'>Dubbione</h2>
					)}

					{location.pathname.includes('/profilo') && (
						// Add your content here that should be rendered only when location.pathname is "/setup-lega"
						<h2 className='fw-bolder text-dark'>Profilo</h2>
					)}

					{location.pathname.includes('/upgrade') && (
						// Add your content here that should be rendered only when location.pathname is "/setup-lega"
						<h2 className='fw-bolder text-dark'>Premium</h2>
					)}
				</HeaderLeft>
				<HeaderRight>
					<div className='row g-3'>
						<div
							className='col d-flex align-items-center cursor-pointer justify-content-end'
							role='presentation'>
							<div className='me-3'>
								<div className='text-end'>
									<div className='fw-bold fs-6 mb-0'>Bisogno d'aiuto?</div>
									<div className='text-muted'>
										<small>Contattaci</small>
									</div>
								</div>
							</div>
							<div className='col-auto'>
								<button
									type='button'
									className='btn btn-lg btn-hover-shadow btn-light-light btn-only-icon'
									data-tour='whatsapp'
									aria-label='Scrivici su WhatsApp'
									onClick={() => {
										setWhatsappModalOpen(true);
									}}>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='16'
										height='16'
										fill='#25D366'
										className='bi bi-whatsapp'
										viewBox='0 0 16 16'>
										<path d='M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z' />
									</svg>
								</button>
							</div>
						</div>
					</div>
				</HeaderRight>
			</Header>
			<Modal isOpen={whatsappModalOpen} setIsOpen={setWhatsappModalOpen} isCentered={true}>
				<ModalHeader
					showClose={false}
					className='d-flex justify-content-between align-items-center'>
					<ModalTitle id={'whatsapp-title'}>Bisogno d'aiuto?</ModalTitle>
					<Button
						className='p-1 px-2 rounded-1'
						onClick={() => {
							setWhatsappModalOpen(false);
						}}
						style={{ color: 'gray' }}>
						<Icon icon={'Close'} size={'2x'}></Icon>
					</Button>
				</ModalHeader>
				<ModalBody className='d-flex flex-column justify-content-start align-items-center gap-2'>
					<p>Per assistenza, contatta il nostro team tramite i seguenti canali:</p>
					<div className='d-flex flex-column'>
						<div className='d-flex align-items-center justify-content-between gap-3'>
							<Icon icon={'Email'} size={'3x'}></Icon>
							<h5 className='mb-0'>Email: info@fantalgoritmo.it</h5>
						</div>
						<div className='d-flex align-items-center ms-1'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='32'
								height='32'
								fill='#25D366'
								className='bi bi-whatsapp'
								viewBox='0 0 16 16'>
								<path d='M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z' />
							</svg>
							<h5 className='mb-0 ms-3'>WhatsApp: +39 351 5125925</h5>
						</div>
					</div>
					<p className='text-center mt-3'>
						Siamo disponibili per rispondere a tutte le tue domande e aiutarti con
						qualsiasi problema riguardante il tuo abbonamento o l'utilizzo del nostro
						servizio.
					</p>
				</ModalBody>
			</Modal>
		</>
	);
};

export default DefaultHeader;
